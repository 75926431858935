import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const DragContext = createContext();

function DragProvider({ children }) {
  const [draggingDrill, setDraggingDrill] = useState(null);
  const contextValue = useMemo(() => ({ draggingDrill, setDraggingDrill }), [draggingDrill]);

  return <DragContext.Provider value={contextValue}>{children}</DragContext.Provider>;
}

DragProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DragProvider;
