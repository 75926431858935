import english from "./en-GB/messages.json";
import danish from "./da-DK/messages.json";
import icelandic from "./isl/messages.json";
import polish from "./pl/messages.json";

export const SUPPORTED_LANGUAGES = {
  english: "en-GB",
  icelandic: "is-IS",
  polish: "pl-PL",
  danish: "da-DK",
};

const translations = {
  [SUPPORTED_LANGUAGES.english]: english,
  [SUPPORTED_LANGUAGES.icelandic]: icelandic,
  [SUPPORTED_LANGUAGES.polish]: polish,
  [SUPPORTED_LANGUAGES.danish]: danish,
};

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.icelandic;

export default translations;
