/* eslint-disable quote-props */
// eslint-disable-next-line import/prefer-default-export
export const ALDA_REPORT_IFRAME_URL = "https://aldapowerbi.azurewebsites.net";

export const OBJECT_INTERPOLATE_STRING = "\\$<< ([\\w\\d[\\].]+) >>";
export const INTL_INTERPOLATE_STRING = "(intl\\.[\\w\\.]+)";

export const LOCALE_MAPPINGS = {
  // English variants
  "en-US": "en-GB",
  "en-GB": "en-GB",

  // Nordic languages
  pl: "pl-PL",
  "pl-PL": "pl-PL",
  is: "is-IS",
  "is-IS": "is-IS",
  da: "da-DK",
  "da-DK": "da-DK",
};

export const normalizeLocale = (locale) => LOCALE_MAPPINGS[locale] || locale;
