import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { isNull } from "lodash-es";
import { db } from "../../../config/firebase";
import { getDataFromSnapsArray } from "../../../utils/firebase";
import { removeWhiteSpaces } from "../../../utils/utils";
import { uploadFile } from "../storage";

export const fetchAllCategories = async () => {
  const masterCategoriesRef = collection(db, "category/master/collection");
  const subCategoriesRef = collection(db, "category/sub/collection");

  const masterCatSnap = await getDocs(masterCategoriesRef);
  const subCatSnap = await getDocs(subCategoriesRef);

  const masterCategories = getDataFromSnapsArray(masterCatSnap);
  const subCategories = getDataFromSnapsArray(subCatSnap);

  return { masterCategories, subCategories };
};

export const submitNewMasterCategory = async (data) => {
  const id = removeWhiteSpaces(data?.name);
  const newDoc = doc(db, "category/master/collection", id);
  await setDoc(newDoc, { ...data, id });
  const categories = await fetchAllCategories();
  return categories;
};

export const submitNewSubCategory = async (data) => {
  const id = removeWhiteSpaces(data?.name);
  const newDoc = doc(db, "category/sub/collection", id);

  let image = data?.image;
  if (!isNull(image)) {
    const url = await uploadFile({ file: image, path: "app-data/categories" });
    image = url;
  }

  await setDoc(newDoc, { id, ...data, image });
  const categories = await fetchAllCategories();
  return categories;
};
