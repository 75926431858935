import PropTypes from "prop-types";
import { VStack } from "@chakra-ui/react";

function FormComponentBox({ children }) {
  return (
    <VStack borderRadius="8px" padding="12px" bg="white" shadow="3" marginBottom="12px">
      {children}
    </VStack>
  );
}

FormComponentBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormComponentBox;
