import {
  collection,
  getDocs,
  limit,
  orderBy as orderByFirebase,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { isEmpty, isNull, isUndefined } from "lodash-es";
import { fetchAllCompanies } from "./company";
import { fetchAllUsers, getUserByUid } from "./user";
import { fetchAllVessels } from "./vessel";
import { db } from "../../../config/firebase";
import { fetchAllCategories } from "./category";

export const fetchAllData = async ({ userId, role, vesselId, companyId }) => {
  const users = await fetchAllUsers(role, vesselId, companyId);
  const companies = await fetchAllCompanies(role, vesselId, companyId);
  const vessels = await fetchAllVessels(role, vesselId, companyId);
  const { masterCategories, subCategories } = await fetchAllCategories();

  const userData = getUserByUid({ uid: userId });

  return {
    users,
    companies,
    vessels,
    masterCategories,
    subCategories,
    userData,
  };
};

export const searchDataWithQuery = async ({
  orderBy = "fullName",
  isAscendingOrder,
  itemsPerPage = 25,
  cursor = null,
  collection: selectedCollection,
  operator,
  entity,
  value,
  vesselId,
}) => {
  const collectionRef = collection(db, selectedCollection);

  const constraints = [limit(itemsPerPage)];

  const direction = isAscendingOrder ? "asc" : "desc";

  if (orderBy !== entity) {
    const order = orderByFirebase(orderBy, direction);
    constraints.push(order);
  }

  if (!isNull(cursor) && !isUndefined(cursor)) {
    constraints.push(startAfter(cursor));
  }

  if (!isUndefined(entity) && !isUndefined(operator) && !isUndefined(value) && !isEmpty(value)) {
    constraints.push(where(entity, operator, value));
  }

  if (selectedCollection === "users" && !isUndefined(vesselId)) {
    constraints.push(where("vesselId", "==", vesselId));
  }

  const queryBuilt = query(collectionRef, ...constraints);

  const snapshots = await getDocs(queryBuilt);

  const results = [];

  snapshots.forEach((s) => {
    const data = s.data();
    const { id } = s;

    const result = { ...data, id };

    results.push(result);
  });

  return { result: results, docs: snapshots.docs };
};
