import {
  // eslint-disable-next-line max-len
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import { isNull, isUndefined } from "lodash-es";
import { auth, setPersistence, browserLocalPersistence } from "../../config/firebase";
// eslint-disable-next-line import/no-cycle
import { getUserByUid } from "./firestore/user";
import { fetchVesselById } from "./firestore/vessel";
import { startAuth } from "../api/api";
import { useAuthStore } from "../../store/auth";

export const signIn = async ({ email, password }) => {
  await setPersistence(auth, browserLocalPersistence);
  const { user } = await signInWithEmailAndPassword(auth, email, password);

  const { uid, refreshToken, accessToken } = user;

  const appUserData = await getUserByUid({ uid });

  const vesselId = appUserData?.vesselId;
  const role = appUserData?.role;

  const vesselData = await fetchVesselById({ vesselId });

  if (isUndefined(role) || isNull(role)) {
    throw new Error("auth/no-access");
  }

  return {
    uid,
    refreshToken,
    accessToken,
    email,
    user: { ...appUserData, vesselField: vesselData },
  };
};

export const signInToken = async (data) => {
  const { phoneNumber, isApp } = data;
  const authData = await startAuth(phoneNumber, isApp);

  if (authData?.errorCode && authData?.message) {
    return authData;
  }

  if (isUndefined(authData) || isNull(authData)) {
    throw new Error("auth/no-connection");
  }

  await setPersistence(auth, browserLocalPersistence);
  const { user } = await signInWithCustomToken(auth, authData);

  const { uid, refreshToken, accessToken, email } = user;

  const appUserData = await getUserByUid({ uid });

  const vesselId = appUserData?.vesselId;
  const role = appUserData?.role;

  const vesselData = await fetchVesselById({ vesselId });

  if (isUndefined(role) || isNull(role)) {
    throw new Error("auth/no-access");
  }

  return {
    uid,
    refreshToken,
    accessToken,
    email,
    user: { ...appUserData, vesselField: vesselData },
  };
};

export const signInTokenCustom = async (token) => {
  if (isUndefined(token) || isNull(token)) {
    throw new Error("auth/no-connection");
  }

  await setPersistence(auth, browserLocalPersistence);
  const { user } = await signInWithCustomToken(auth, token);

  const { uid, refreshToken, accessToken, email } = user;

  const appUserData = await getUserByUid({ uid });

  const vesselId = appUserData?.vesselId;
  const role = appUserData?.role;

  const vesselData = await fetchVesselById({ vesselId });

  if (isUndefined(role) || isNull(role)) {
    throw new Error("auth/no-access");
  }

  return {
    uid,
    refreshToken,
    accessToken,
    email,
    user: { ...appUserData, vesselField: vesselData },
  };
};

export const resetPassword = async ({ email }) => {
  await sendPasswordResetEmail(auth, email);
};

export const login = async ({ email }) => {
  const response = await new Promise((resolve) => {
    setTimeout(() => {
      resolve({ email });
    }, 3000);
  });

  return response;
};

export const logout = async () => {
  const response = await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 3000);
  });

  return response;
};

export const createUser = async ({ email, password }) => {
  const { user } = await createUserWithEmailAndPassword(auth, email, password);

  const { uid } = user;

  return { uid };
};
