import { useMutation } from "react-query";
import { useAuthStore } from "../store/auth";
import { logout } from "../services/firebase/auth";
import { useUserStore } from "../store/user";
import useToast from "./useToast";

const useLogout = () => {
  const { setIsAuthenticated } = useAuthStore();
  const { resetUser } = useUserStore();
  const toast = useToast();

  const { isLoading, mutate } = useMutation(logout, {
    onMutate: () => {
      toast({
        status: "mutate",
      });
    },
    onSuccess: () => {
      resetUser();
      setIsAuthenticated(false);
    },
    onError: () => {},
  });

  return {
    isLoading,
    mutate,
  };
};

export default useLogout;
