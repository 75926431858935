import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Text, VStack } from "@chakra-ui/react";
import { formatDate } from "../../utils/date";

function DateInput({ name, label, required, mode, labelProps, background, manualDate }) {
  const intl = useIntl();
  const {
    formState: { errors },
  } = useFormContext();

  const date = manualDate ?? new Date();

  const requiredErrMessage = intl.formatMessage({
    id: "ERR_FIELD_REQUIRED",
    defaultMessage: "This field is required.",
  });

  return (
    <>
      {!isEmpty(label) && (
        <Text
          color={background ? "gray.700" : undefined}
          variant="label"
          marginBottom="8px"
          {...labelProps}
        >
          {label}
        </Text>
      )}
      <Controller
        name={name}
        rules={{ required }}
        render={({ field: { value } }) => (
          <>
            <VStack
              key={name}
              id={name}
              width="100%"
              bg={background ? "primary.500" : undefined} // Set the background color based on the 'background' prop
              borderRadius="full"
              paddingX={background ? "16px" : undefined} // Add horizontal padding when 'background' is true
              paddingY={background ? "8px" : undefined}
            >
              <Text color={background ? "white" : undefined} fontWeight="bold">
                {formatDate(value || date, mode)}
              </Text>
            </VStack>
            {errors[name] && <Text variant="error">{requiredErrMessage}</Text>}
          </>
        )}
      />
    </>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  mode: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
  background: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  manualDate: PropTypes.any,
};

DateInput.defaultProps = {
  label: null,
  required: false,
  mode: "date",
  labelProps: {},
  background: true,
  manualDate: new Date(),
};

export default DateInput;
