import {
  addDoc,
  // eslint-disable-next-line no-unused-vars
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy as orderByFirebase,
  setDoc,
  where,
  query,
} from "firebase/firestore";
import { isUndefined, isNull, isObject } from "lodash-es";
import { db } from "../../../config/firebase";
import { uploadFile } from "../storage";
// eslint-disable-next-line import/no-cycle
import { fetchVesselsByCompanyId } from "./vessel";

const collectionName = "company";
const companiesCollRef = collection(db, collectionName);

export const fetchAllCompanies = async (role, vesselId, companyId) => {
  if (isUndefined(vesselId) || isNull(vesselId)) {
    return [];
  }

  if (isUndefined(companyId) || isNull(companyId)) {
    return [];
  }

  if (isUndefined(role) || isNull(role)) {
    return [];
  }

  let whereQuery = where("id", "==", companyId);

  if (role === "SUPER_ADMIN") {
    whereQuery = null;
  }

  const constraints = [whereQuery];

  const companiesQuery = query(companiesCollRef, ...constraints);

  const snap = await getDocs(companiesQuery);

  const companies = [];

  snap.forEach((c) => {
    const data = c.data();
    const { id } = c;

    const company = { id, ...data };

    companies.push(company);
  });

  return companies;
};

export const fetchCompanyById = async ({ companyId }) => {
  const docRef = doc(db, collectionName, companyId);
  const snap = await getDoc(docRef);

  const data = snap.data();

  const { result } = await fetchVesselsByCompanyId({ companyId });

  return { company: data, vessels: result };
};

export const updateCompany = async ({ data, documentId }) => {
  const docRef = doc(db, collectionName, documentId);
  const lastUpdateAt = new Date().toISOString();
  await setDoc(docRef, { ...data, lastUpdateAt }, { merge: true });
};

export const createCompany = async (data) => {
  const createdAt = new Date().toISOString();

  const { logo } = data;
  const { id } = await addDoc(companiesCollRef, {
    ...data,
    logo: null,
    vessels: [],
    createdAt,
  });

  if (!isNull(logo) && isObject(logo)) {
    // eslint-disable-next-line no-param-reassign
    data.logo = await uploadFile({ file: logo, path: `companies/${id}` });
  }

  await updateCompany({ data: { id, logo: data?.logo }, documentId: id });

  return { id };
};
