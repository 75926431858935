import { useEffect } from "react";
import { HStack } from "@chakra-ui/react";
import LanguageOption from "./LanguageOption";
import { useAppStore } from "../../store/app";

import britain from "../../assets/britain.png";
import iceland from "../../assets/iceland.png";
import poland from "../../assets/poland.svg";
import danish from "../../assets/denmark.png";

const translations = {
  "en-GB": {
    value: "en-GB",
    flag: britain,
    translation: {
      id: "LANG_ENGLISH",
      defaultMessage: "English",
    },
  },
  "da-DK": {
    value: "da-DK",
    flag: danish,
    translation: {
      id: "LANG_DANISH",
      defaultMessage: "Danish",
    },
  },
  "is-IS": {
    value: "is-IS",
    flag: iceland,
    translation: {
      id: "LANG_ICELANDIC",
      defaultMessage: "Icelandic",
    },
  },
  "pl-PL": {
    value: "pl-PL",
    flag: poland,
    translation: {
      id: "LANG_POLISH",
      defaultMessage: "Polish",
    },
  },
};

function LanguageSwitcher() {
  const { language, setLanguage } = useAppStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));

  // Check if current language is valid, if not default to English
  useEffect(() => {
    if (!translations[language]) {
      setLanguage("en-GB");
    }
  }, [language, setLanguage]);

  return (
    <HStack spacing="4px">
      {Object.keys(translations).map((key) => (
        <LanguageOption key={key} isSelected={key === language} {...translations[key]} />
      ))}
    </HStack>
  );
}

LanguageSwitcher.propTypes = {};

export default LanguageSwitcher;
