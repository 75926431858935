/* eslint-disable spaced-comment */
/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import { useEffect, useState } from "react";
import { Link as RoterLink } from "react-router-dom";
import { FaInfoCircle, FaBars, FaBell } from "react-icons/fa";
import { BiChevronRight } from "react-icons/bi";

import {
  Box,
  Container,
  HStack,
  VStack,
  Image,
  Button,
  Link,
  useDisclosure,
  useBreakpointValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
  Text,
  Avatar,
  Divider,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import propTypes from "prop-types";
import { pathNames } from "../../config/pathNames";
import UserMenu from "../Menu/User/UserMenu";
// eslint-disable-next-line import/no-named-as-default
import DesktopMenu from "../Menu/Desktop/DesktopMenu";
import MobileMenu from "../Menu/Mobile/MobileMenu";
// import bell from "../../assets/bell.svg"
import LanguageMenu from "../LanguageMenu/LanguageMenu";
import britain from "../../assets/britain.png";
import iceland from "../../assets/iceland.png";
import poland from "../../assets/poland.svg";
import danish from "../../assets/denmark.png";
import { useAppStore } from "../../store/app";
import {
  fetchAllNotifications,
  markNotificationsAsRead,
} from "../../services/firebase/firestore/notifications";
import { userStore } from "../../store/user";

function NotificationBadge({ count }) {
  if (count <= 0) return null;

  return (
    <Badge
      position="absolute"
      top="0"
      right="0"
      borderRadius="full"
      bgColor="red.500"
      color="white"
      fontSize="sm"
      w={5}
      h={5}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {count}
    </Badge>
  );
}

const { REACT_APP_ENVIRONMENT } = process.env;

function Header() {
  const { formatMessage } = useIntl();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isMdBreakpoint = useBreakpointValue({ base: false, xl: true });
  // const isXlBreakPoint = useBreakpointValue({ base: false, xl: true })
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const { language } = useAppStore((state) => ({ language: state.language }));
  const [notifications, setNotifications] = useState([]);
  const [showBadge, setShowBadge] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const user = userStore.getState();

  const { data, isLoading, isError } = useQuery("notifications", fetchAllNotifications, {
    refetchInterval: 25000,
    // eslint-disable-next-line no-shadow
    onSuccess: (data) => {
      const sortedNotifications = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotifications(sortedNotifications);

      const unreadNotifications = sortedNotifications.filter(
        (notif) => !notif.readBy?.includes(user.id)
      );
      setUnreadCount(unreadNotifications.length);
    },
  });

  useEffect(() => {
    if (data && !isLoading && !isError) {
      const sortedNotifications = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotifications(sortedNotifications);
      const unreadNotifications = sortedNotifications.filter(
        (notif) => !notif.readBy?.includes(user.id)
      );
      setUnreadCount(unreadNotifications.length);
    }
  }, [data, isLoading, isError, user.id]);

  async function updateNotifications() {
    try {
      await markNotificationsAsRead(notifications);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to mark notifications as read:", error);
    }
  }

  const handleNotificationClick = () => {
    if (!notifications || notifications.length === 0) {
      return;
    }

    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      readBy: [...(notification.readBy || []), user.uid],
    }));

    setNotifications(updatedNotifications);
    updateNotifications();
    setUnreadCount(0);
  };

  useEffect(() => {
    if (isMdBreakpoint) onClose();
  }, [isMdBreakpoint, onClose]);

  function helperFunction() {
    if (language === "en-GB") return britain;
    if (language === "da-DK") return danish;
    if (language === "PL") return poland;
    return iceland;
  }

  return (
    <>
      {REACT_APP_ENVIRONMENT === "staging" && (
        <Box bg="red.500" p={2}>
          <VStack w="100%" justifyContent="center" alignItems="center">
            <Text color="white" textShadow="0px 1px 3px rgba(0,0,0,0.5)">
              Þetta er
              {" "}
              <strong>STAGING</strong>
              {" "}
              umhverfi.
            </Text>
          </VStack>
        </Box>
      )}
      <Box paddingY={isMdBreakpoint ? 0 : 2} marginY={isMdBreakpoint ? 25 : 0} padding={2}>
        <Container maxWidth="container.2xl">
          <VStack width="100%">
            <HStack
              width="100%"
              height="auto"
              minHeight="100%"
              alignItems="stretch"
              justifyContent="space-between"
              marginTop={isMdBreakpoint ? 2 : 0}
            >
              {!isMdBreakpoint && (
                <Button
                  onClick={onToggle}
                  variant="ghost"
                  aria-label={formatMessage({
                    id: "OPEN_MOBILE_MENU",
                    defaultMessage: "Open Mobile Menu",
                  })}
                >
                  <FaBars color="#677788" />
                </Button>
              )}
              <HStack height="auto" minHeight="100%" alignItems="stretch">
                <Box display="flex" justifyContent="center" alignItems="center" px={10}>
                  <Link
                    to={pathNames.home}
                    as={RoterLink}
                    aria-label={formatMessage({ id: "HOME_PAGE", defaultMessage: "Home" })}
                  >
                    <Image src="/img/logo250x77.png" maxHeight="80px" />
                  </Link>
                </Box>
              </HStack>
              {isMdBreakpoint && (
                <HStack width="100%">
                  <DesktopMenu />
                </HStack>
              )}
              <HStack spacing={5} mr={5}>
                {/* Informational "i" icon */}
                <IconButton
                  variant="ghost"
                  to={pathNames.information}
                  as={RoterLink}
                  icon={<FaInfoCircle color="#677788" size={isMdBreakpoint ? 24 : 20} />}
                  onClick={async () => {
                    await handleNotificationClick();
                    setShowBadge(false);
                  }}
                />

                {/* Notification bell icon */}
                <Popover placement="bottom">
                  <PopoverTrigger>
                    <Box position="relative">
                      <IconButton
                        variant="ghost"
                        icon={<FaBell color="#677788" size={isMdBreakpoint ? 24 : 20} />}
                        onClick={async () => {
                          await handleNotificationClick();
                          setShowBadge(false);
                        }}
                      />
                      <NotificationBadge count={unreadCount} />
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent boxShadow="xl" width="400px" overflowY="auto">
                    {/* <PopoverArrow bg="white" /> */}
                    <PopoverCloseButton />
                    <PopoverHeader>
                      <Text color="gray.600" fontWeight="bold" fontSize="md" px={4} py={4}>
                        {formatMessage({ id: "NOTIFICATIONS", defaultMessage: "Notifications" })}
                      </Text>
                    </PopoverHeader>
                    <PopoverBody m={0} p={0}>
                      <VStack spacing={0}>
                        {notifications.slice(0, 4).map((notification) => (
                          <HStack
                            key={notification.id}
                            w="100%"
                            _hover={{ background: "gray.100", cursor: "pointer" }}
                            p={3}
                            as={RoterLink}
                            to={pathNames.alertsCenter}
                          >
                            <Avatar
                              name={notification.name}
                              src={notification.image || "/assets/avatar.png"}
                              mx={2}
                              size="md"
                            />

                            <Box>
                              <Text mb={1} fontWeight="700" color="gray.600">
                                {notification.data.sender?.fullName || "Unknown Sender"}
                              </Text>

                              {Object.values(notification.data.message.contents).map(
                                (value, index) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <Text key={index} fontWeight="500">
                                    {value}
                                  </Text>
                                )
                              )}
                            </Box>
                          </HStack>
                        ))}
                        <Divider borderColor="gray.300" thickness="1px" />
                        <Link
                          color="gray.600"
                          to={pathNames.alertsCenter}
                          as={RoterLink}
                          w="100%"
                          display="block"
                          textAlign="center"
                          fontSize="md"
                          fontWeight="bold"
                          my={3}
                        >
                          <HStack justifyContent="center">
                            <Box>
                              {formatMessage({
                                id: "SHOW_ALL_NOTIFICATIONS",
                                defaultMessage: "Show all notifications",
                              })}
                            </Box>
                            <BiChevronRight />
                          </HStack>
                        </Link>
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>

                {/* Language menu */}
                <Popover>
                  <PopoverTrigger>
                    <Image
                      cursor="pointer"
                      alt=""
                      onClick={() => setOpenLangMenu(!openLangMenu)}
                      width={6}
                      src={helperFunction()}
                    />
                  </PopoverTrigger>
                  <PopoverContent boxShadow="xl" width="200px" marginTop="10px">
                    <PopoverCloseButton />
                    <LanguageMenu />
                  </PopoverContent>
                </Popover>

                {/* User menu */}
                <UserMenu />
              </HStack>
            </HStack>
          </VStack>
        </Container>
        {!isMdBreakpoint && <MobileMenu isOpen={isOpen} onToggle={onToggle} />}
      </Box>
    </>
  );
}

NotificationBadge.propTypes = {
  count: propTypes.number.isRequired,
};

Header.propTypes = {};

export default Header;
