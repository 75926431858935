import { Avatar, HStack, Text, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaChevronRight } from "react-icons/fa";
import { useIntl } from "react-intl";
import { isUndefined, isEmpty } from "lodash-es";
import { createAvatarInitials } from "../../utils";

function CrewSelect({ multiple, name, required, label, labelProps, containerProps }) {
  const intl = useIntl();
  const { setValue, getValues, control } = useFormContext();

  const isInputDisabled = getValues("formDisabled") || false;

  useEffect(() => {
    const formValues = getValues(name);

    if (isInputDisabled) {
      setValue(name, formValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack
      width="100%"
      paddingX="16px"
      paddingY="12px"
      borderRadius="8px"
      borderWidth="1px"
      borderColor="gray.200"
      justifyContent="space-between"
      {...containerProps}
    >
      <HStack alignItems="center" justifyContent="space-between" width="100%">
        <Text {...labelProps}>{label}</Text>
        <FaChevronRight />
      </HStack>
      <Controller
        key={name}
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value } }) => (
          <VStack width="100%" alignItems="flex-start">
            {multiple && !isUndefined(value) && (
              <>
                <Text textAlign="left" fontSize="10px" color="gray.400">
                  {`${intl.formatMessage({ id: "SELECTED", defaultMessage: "Selected" })}: ${value?.length}`}
                </Text>
                {value?.map((v) => (
                  <HStack
                    width="100%"
                    borderWidth="1px"
                    borderColor="gray.200"
                    paddingX="10px"
                    paddingY="6px"
                    key={v?.id || v?.email}
                  >
                    <VStack alignItems="flex-start">
                      <Text>{v?.fullName}</Text>
                      <Text fontSize="10px" color="gray.400">
                        {v?.title || v?.role}
                      </Text>
                    </VStack>
                  </HStack>
                ))}
              </>
            )}
            {!multiple && !isUndefined(value) && (
              <HStack alignItems="center" justifyContent="space-between">
                <HStack alignItems="center" space="8px" marginTop="16px">
                  <Avatar
                    source={{ uri: !isEmpty(value?.image?.trim()) ? value?.image : null }}
                    key={value?.fullName}
                    borderWidth="3px"
                    borderColor="white"
                  >
                    {createAvatarInitials(value?.fullName)}
                  </Avatar>
                  <VStack alignItems="flex-start">
                    <Text fontSize="12px">{value?.fullName}</Text>
                    <Text color="gray.600" fontSize="10px">
                      {value.title}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            )}
          </VStack>
        )}
      />
    </VStack>
  );
}

CrewSelect.propTypes = {
  multiple: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  containerProps: PropTypes.object,
  userDefault: PropTypes.bool,
};

CrewSelect.defaultProps = {
  multiple: false,
  name: "participants",
  required: false,
  label: "CrewSelect label",
  labelProps: {},
  containerProps: {},
  userDefault: true,
};

export default CrewSelect;
