import PropTypes from "prop-types";
import {
  Box,
  Divider,
  HStack,
  Text,
  Icon,
  Avatar,
  Skeleton,
  SkeletonCircle,
  Link,
  Flex,
  useBreakpointValue,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@chakra-ui/react";
import { FaSignOutAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";
import { useState } from "react";
import { getUserMenu } from "../config";
import { useUserStore } from "../../../store/user";
import { useAuthStore } from "../../../store/auth";
// eslint-disable-next-line no-unused-vars
import { pathNames } from "../../../config/pathNames";
import useLogout from "../../../hooks/useLogout";
import { useGetUser } from "../hooks";
import styles from "./UserMenu.module.scss";
import messages from "../messages";

function UserMenu() {
  const { formatMessage } = useIntl();
  const { uid } = useAuthStore();
  const user = useUserStore();
  const { mutate: logout, isLoading: isLoggingOut } = useLogout();
  const { isLoading: isFetchingUser } = useGetUser();
  const isMdBreakpoint = useBreakpointValue({ base: false, md: true });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isLoading = isLoggingOut || isFetchingUser;
  const error = null;

  const handleTogglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleBlur = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Popover isOpen={isPopoverOpen} onClose={() => setIsPopoverOpen(false)} closeOnBlur={false}>
      <PopoverTrigger>
        <Button
          borderRadius="full"
          p={0}
          h="auto"
          w="auto"
          _focus={{
            borderColor: "gray.100",
          }}
          aria-label={formatMessage({ id: "OPEN_USER_MENU", defaultMessage: "Open User Menu" })}
          onClick={handleTogglePopover}
        >
          <Avatar
            src={user?.image}
            name={user && user?.fullName}
            size="md"
            color="black"
            backgroundColor="white"
            borderWidth="1px"
            borderColor="gray.500"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent tabIndex={-1} onBlur={handleBlur}>
        {error && (
          <Box padding={[4, 3]} width="100%" maxWidth={56}>
            <Text>User MenuList Error</Text>
          </Box>
        )}

        {isLoading && (
          <>
            <HStack spacing={4} border="1px solid" borderColor="transparent" padding={[4, 3]}>
              <SkeletonCircle width="32px" height="32px" />
              <Skeleton height="24px" flex={1} />
            </HStack>
            <Divider />
            {getUserMenu().map(({ path }) => (
              <Box
                key={path}
                border="1px solid"
                borderColor="transparent"
                padding={[4, 3]}
                width="100%"
              >
                <Skeleton height="24px" width="100%" />
              </Box>
            ))}
          </>
        )}

        {!isLoading && !error && (
          <>
            <HStack
              className={styles.header}
              // _hover={{ Link is now Text because it won't nav to profile anymore (check it ??)
              //   backgroundColor: 'gray.100',
              // }}
            >
              {/* <Text> */}
              <HStack spacing={2} my={4}>
                <Avatar src={user?.image} name={user?.fullName} size="md" />
                {/* TODO: Update user name in user menu */}
                <VStack spacing={0}>
                  <Text color="gray.900">{user?.fullName}</Text>
                  <Text color="gray.500">{user?.email}</Text>
                </VStack>
              </HStack>
              {/* </Text> */}
            </HStack>
            <Divider />
            <Flex
              padding={[4, 2]}
              display="flex"
              flexDirection="column"
              fontSize={14}
              justifyContent="flex-start"
              paddingLeft={isMdBreakpoint ? 10 : 5}
            >
              <Link
                key={pathNames.profile}
                as={NavLink}
                onClick={() => setIsPopoverOpen(false)}
                to={`${pathNames.users}/view/${uid}`}
                _hover={{
                  backgroundColor: "gray.100",
                }}
                className={isMdBreakpoint ? styles.menuItem : styles.menuItemMobile}
                color="#5F5E5E"
              >
                {formatMessage(messages.profile)}
              </Link>
              <Link
                key={pathNames.Settings}
                as={NavLink}
                onClick={() => setIsPopoverOpen(false)}
                to={`${pathNames.settings}`}
                _hover={{
                  backgroundColor: "gray.100",
                }}
                className={isMdBreakpoint ? styles.menuItem : styles.menuItemMobile}
                color="#5F5E5E"
              >
                {formatMessage(messages.userSettings)}
              </Link>
              <Link
                key={pathNames.feedback}
                as={NavLink}
                onClick={() => setIsPopoverOpen(false)}
                to={`${pathNames.feedback}`}
                _hover={{
                  backgroundColor: "gray.100",
                }}
                className={isMdBreakpoint ? styles.menuItem : styles.menuItemMobile}
                color="#5F5E5E"
              >
                {formatMessage(messages.feedback)}
              </Link>
            </Flex>

            <Divider />

            <Button
              borderRadius={0}
              backgroundColor="white"
              justifyContent="flex-start"
              paddingLeft="1.4rem"
              fontWeight={500}
              onClick={logout}
            >
              <HStack spacing={2} align="start">
                <Icon color="gray.900" as={FaSignOutAlt} />
                <Text color="gray.900">
                  {formatMessage({ id: "LOGOUT", defaultMessage: "Logout" })}
                </Text>
              </HStack>
            </Button>
          </>
        )}
      </PopoverContent>
    </Popover>
  );
}

UserMenu.propTypes = {};

export default UserMenu;
