/* eslint-disable no-unused-vars */
import { VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import FormHeader from "../FormHeader/FormHeader";
// eslint-disable-next-line import/no-unresolved
// import FormFooter from '../FormFooter/FormFooter';

function Form({
  children,
  url,
  title,
  formDefaultValues,
  subCategoryId,
  masterCategoryId,
  id,
  ...rest
}) {
  return (
    <VStack padding="16px" alignItems="flex-start">
      <FormHeader title={title} />
      {children}
      {/* <FormFooter /> */}
    </VStack>
  );
}

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  subCategoryId: PropTypes.string,
  masterCategoryId: PropTypes.string,
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formDefaultValues: PropTypes.object,
};

Form.defaultProps = {
  url: null,
  formDefaultValues: {},
  subCategoryId: "",
  masterCategoryId: "",
  id: null,
};

export default Form;
