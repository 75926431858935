/* eslint-disable no-nested-ternary */
import { Box, VStack, HStack, CircularProgress, Text } from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

function Button({ isAction, title, description, status }) {
  if (isAction) {
    return (
      <Box borderRadius={6} p={4} bg="blue.500" boxShadow="0px 0px 20px 5px rgba(0,0,0,0.1)">
        <HStack spacing={4}>
          <VStack alignItems="center" justifyContent="center">
            <CircularProgress trackColor="transparent" color="white" isIndeterminate size="40px" />
          </VStack>
          <VStack alignItems="center" justifyContent="center">
            <Text color="white" fontWeight="700">
              {title}
            </Text>
          </VStack>
        </HStack>
      </Box>
    );
  }

  const isWarning = status === "warning";
  const isSuccess = status === "success";

  return (
    <Box
      borderRadius={6}
      p={4}
      bg={isSuccess ? "green.500" : isWarning ? "orange.500" : "red.500"}
      boxShadow="0px 0px 20px 5px rgba(0,0,0,0.1)"
    >
      <HStack spacing={4}>
        <VStack alignItems="center" justifyContent="center">
          {isSuccess ? (
            <FaCheckCircle color="#fff" size="40px" />
          ) : (
            <FaExclamationCircle color="#fff" size="40px" />
          )}
        </VStack>
        <VStack alignItems="start" justifyContent="start">
          <Text color="white" fontWeight="700">
            {title}
          </Text>
          <Text color="white">{description}</Text>
        </VStack>
      </HStack>
    </Box>
  );
}

Button.propTypes = {
  isAction: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
};

Button.defaultProps = {
  isAction: false,
  title: "",
  description: "",
  status: "error",
};

export default Button;
