import { useQuery } from "react-query";
import { useAppStore } from "../../store/app";
import { fetchTranslations } from "../../services/azureApiTranslations/settings";

// eslint-disable-next-line import/prefer-default-export
export const useLocale = () => {
  const { language } = useAppStore((state) => ({ language: state.language }));

  const { isLoading, data } = useQuery(["locale", language], () => fetchTranslations(language), {
    onSuccess: () => {},
    onError: () => {},
  });

  return {
    isLoading,
    data,
    locale: language,
  };
};
