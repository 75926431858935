import * as Sentry from "@sentry/react";

import { useQuery } from "react-query";
import { useIntl } from "react-intl";
import { fetchAllData } from "../../services/firebase/firestore/data";
import useToast from "../../hooks/useToast";
import { useAuthStore } from "../../store/auth";
import { useDataStore } from "../../store/data";
import { useUserStore } from "../../store/user";

// eslint-disable-next-line import/prefer-default-export
export const useDataSync = async () => {
  const { isAuthenticated } = useAuthStore((state) => ({ isAuthenticated: state.isAuthenticated }));
  const { id, setUser, role, vesselId, companyId } = useUserStore();
  const { setData } = useDataStore();

  const { formatMessage } = useIntl();
  const toast = useToast();

  // while (id !== null) {
  //   // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
  //   await new Promise((r) => setTimeout(r, 500));
  // }
  // const queryClient = useQueryClient();

  const { isLoading } = useQuery(
    "appDataSync",
    async () => fetchAllData({
      userId: id,
      role,
      vesselId,
      companyId,
    }),
    {
      onSuccess: ({ users, vessels, companies, subCategories, masterCategories, userData }) => {
        setData(users, vessels, companies, masterCategories, subCategories);
        setUser(userData);
        Sentry.setUser({ email: userData?.email });
      },
      onError: (err) => {
        toast({
          title: formatMessage({
            id: "TOAST.GENERIC_ERROR.HEADING",
            defaultMessage: "Something went wrong",
          }),
          description: err.toString(),
        });
      },
      enabled: isAuthenticated,
    }
  );

  return {
    isLoading,
  };
};
