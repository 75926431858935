import { useContext, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import createElementFromJson from "./createElementFromJson";

const FormProviderContext = createContext({});
FormProviderContext.displayName = "FormProviderContext";

export const useFormProviderComponents = () => {
  const context = useContext(FormProviderContext);

  if (context === undefined) {
    throw new Error("useFormProviderComponents must be used within a FormProvider!");
  }

  return context;
};

export const useCreateElementFromJson = () => {
  const { components, config } = useFormProviderComponents();

  return (json) => createElementFromJson(json, components, config);
};

function FormProvider({ components, config, children }) {
  const memoizedValue = useMemo(
    () => ({
      components,
      config,
    }),
    [components, config]
  );

  return (
    <FormProviderContext.Provider value={memoizedValue}>{children}</FormProviderContext.Provider>
  );
}

FormProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object,
  children: PropTypes.node.isRequired,
};

FormProvider.defaultProps = {
  components: {},
  config: {},
};

export default FormProvider;
