import { Timestamp } from "firebase/firestore";
import { isNumber, isUndefined } from "lodash-es";
import moment from "moment";

const modes = {
  time: "HH:mm:ss",
  date: "DD/MM/YYYY",
  dateTime: "DD/MM/YYYY, HH:mm:ss",
};

const oldModes = {
  time: "HH:mm:ss",
  date: "YYYY-MM-DD",
  dateTime: "DD-MM-YYYY, h:mm:ss a",
};

export const convertDateStringToTimestamp = (dateString) => {
  const timestamp = Timestamp.fromDate(dateString);
  return timestamp;
};

// eslint-disable-next-line no-unused-vars
const formatMomentDate = (value, mode) => {
  const format = modes[mode];
  const oldFormat = oldModes[mode];
  const formattedDate = moment(value, oldFormat).format(format);

  return formattedDate;
};

// mode: date / time / dateTime
export const formatDate = (date, mode) => {
  let ts = date;

  const isDateAnTsInstance = ts instanceof Timestamp;

  if (!isDateAnTsInstance && !isNumber(ts)) {
    const isDateAnTsObject = !isUndefined(date?.seconds);
    if (!isDateAnTsObject) {
      ts = Timestamp.fromDate(new Date(date));
    }
    if (isDateAnTsObject) {
      const tsObjToDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
      ts = Timestamp.fromDate(tsObjToDate);
    }
  }

  if (isNumber(ts)) {
    const dateDigitsLength = date.toString().length;
    const isDateInMilliseconds = dateDigitsLength > 11;
    if (isDateInMilliseconds) {
      ts = Timestamp.fromMillis(date);
    }

    // it is in seconds
    if (!isDateInMilliseconds) {
      const output = new Date(1970, 0, 1);
      output.setSeconds(date);
      ts = output;
      ts = Timestamp.fromDate(ts);
    }
  }

  let retVal;
  if (mode === "date") {
    retVal = ts.toDate().toDateString();
  } else if (mode === "time") {
    retVal = ts.toDate().toLocaleTimeString("en-GB", { hour12: false });
  } else {
    retVal = ts.toDate().toLocaleString("en-GB", { hour12: false });
  }

  if (retVal === "Invalid Date") {
    return formatMomentDate(date, mode);
  }

  return retVal;
};

const convertToTimestamp = (date) => {
  if (date instanceof Timestamp) {
    return date;
  }

  if (typeof date === "number") {
    return Timestamp.fromMillis(date);
  }

  if (date?.seconds) {
    const tsObjToDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    return Timestamp.fromDate(tsObjToDate);
  }

  return Timestamp.fromDate(new Date(date));
};

export const formatDateOnly = (date) => {
  const ts = convertToTimestamp(date);
  return ts.toDate().toLocaleDateString("en-GB");
};

export const formatTimeOnly = (date) => {
  const ts = convertToTimestamp(date);
  return ts.toDate().toLocaleTimeString("en-GB", { hour12: false });
};
