/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import { lazy, Suspense } from "react";
import { useIntl } from "react-intl";
import { Route, Routes } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-unresolved
import * as Sentry from "@sentry/react";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { VStack, Text, Button, Box } from "@chakra-ui/react";
import { pathNames, rootPathNames } from "../../config/pathNames";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import Redirect from "../Redirect/Redirect";
import PageLayout from "../PageLayout/PageLayout";
import { USER_ROLES } from "../../services/constants";
import messages from "./messages";

const NotFound = lazy(() => import("../../pages/NotFound/NotFound"));
const SignIn = lazy(() => import("../../pages/SignIn/SignIn"));
const Home = lazy(() => import("../../pages/Home/Home"));
const Companies = lazy(() => import("../../pages/Companies/Companies"));
const Vessels = lazy(() => import("../../pages/Vessels/Vessels"));
const Users = lazy(() => import("../../pages/Users/Users"));
const Profile = lazy(() => import("../../pages/Profile/Profile"));
const Documents = lazy(() => import("../../pages/Documents/Documents"));
const Reports = lazy(() => import("../../pages/Reports/Reports"));
const Data = lazy(() => import("../../pages/Data/Data"));
const AlertsCenter = lazy(() => import("../../pages/AlertsCenter/AlertsCenter"));
const Feedback = lazy(() => import("../../pages/FeedBack/FeedBack"));
const Assignments = lazy(() => import("../../pages/Assignments/Assignments"));
const SentryTest = lazy(() => import("./SentryTest"));
const AuditLogs = lazy(() => import("../../pages/AuditLogs/AuditLogs"));
const Information = lazy(() => import("../../pages/Information/Information"));
const Settings = lazy(() => import("../../pages/Settings/Settings"));
const CompanyOnboard = lazy(() => import("../../pages/CompanyOnboard/Companyonboard"));
const TrainingSchedule = lazy(() => import("../../pages/TrainingSchedule/TrainingSchedule"));
const DocumentEditor = lazy(
  () => import("../../pages/Documents/views/DocumentEditor/DocumentEditor")
);

const { SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER } = USER_ROLES;

function ServerErrorFallback({ error }) {
  const { formatMessage } = useIntl();
  return (
    <VStack alignItems="center" justifyContent="center" flex={1}>
      <Text fontSize="2xl" fontWeight="bold">
        {formatMessage(messages.serverError)}
      </Text>
      <Box bg="gray.300" p={3} m={1}>
        <Text fontSize="md">{error.message}</Text>
      </Box>
    </VStack>
  );
}

function Fallback({ error }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const { formatMessage } = useIntl();
  const { resetBoundary } = useErrorBoundary();

  if (error?.code === 500) {
    return <ServerErrorFallback error={error} />;
  }

  return (
    <VStack alignItems="center" justifyContent="center" flex={1}>
      <VStack width="100%" justifyContent="center" alignItems="center" spacing={5} maxWidth="50vw">
        <Text fontSize="2xl" fontWeight="bold">
          {formatMessage(messages.error)}
        </Text>
        <Box bg="gray.300" p={3} m={1}>
          <Text fontSize="md">{error.message}</Text>
        </Box>
        <Button variant="regular" onClick={resetBoundary}>
          {formatMessage(messages.tryAgain)}
        </Button>
      </VStack>
    </VStack>
  );
}

const logError = (error, info) => {
  // eslint-disable-next-line no-console
  console.error(error);
  // eslint-disable-next-line no-console
  console.error(info.componentStack);
};

function RoutesWrapper() {
  return (
    <Suspense onError={logError}>
      <PageLayout>
        <Sentry.ErrorBoundary FallbackComponent={Fallback}>
          <Routes>
            <Route
              path={pathNames.companyOnboard}
              element={<ProtectedRoute component={CompanyOnboard} isProtected={false} />}
            />
            <Route
              path={rootPathNames.signIn}
              element={<ProtectedRoute component={SignIn} isProtected={false} />}
            />
            <Route path={rootPathNames.home} element={<ProtectedRoute component={Home} />} />
            <Route path={rootPathNames.data} element={<ProtectedRoute component={Data} />} />
            <Route path={rootPathNames.user} element={<ProtectedRoute component={Users} />} />
            <Route
              path={rootPathNames.companies}
              element={<ProtectedRoute component={Companies} roles={[SUPER_ADMIN]} />} // eg. roles={[SUPER_ADMIN]}
            />
            <Route
              path={rootPathNames.vessels}
              element={(
                <ProtectedRoute
                  component={Vessels}
                  roles={[SUPER_ADMIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
            <Route
              path={rootPathNames.users}
              element={(
                <ProtectedRoute
                  component={Users}
                  roles={[SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
            <Route
              path={rootPathNames.documents}
              element={<ProtectedRoute component={Documents} roles={[SUPER_ADMIN]} />}
            />
            <Route
              path={rootPathNames.cms}
              element={(
                <ProtectedRoute
                  component={DocumentEditor}
                  roles={[SUPER_ADMIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
            <Route
              path={rootPathNames.reports}
              element={(
                <ProtectedRoute
                  component={Reports}
                  roles={[SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
            <Route
              path={rootPathNames.assignments}
              element={(
                <ProtectedRoute
                  component={Assignments}
                  roles={[SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
            <Route path={rootPathNames.profile} element={<ProtectedRoute component={Profile} />} />
            <Route
              path={rootPathNames.alertsCenter}
              element={<ProtectedRoute component={AlertsCenter} />}
            />
            <Route
              path={rootPathNames.feedback}
              element={<ProtectedRoute component={Feedback} />}
            />
            <Route
              path={rootPathNames.information}
              element={<ProtectedRoute component={Information} />}
            />
            <Route
              path={pathNames.root}
              exact
              element={<ProtectedRoute component={Redirect} isProtected={false} />}
            />
            <Route path={pathNames.wildcard} element={<ProtectedRoute component={NotFound} />} />
            <Route
              path={pathNames.auditlogs}
              element={<ProtectedRoute component={AuditLogs} roles={[SUPER_ADMIN]} />}
            />
            <Route path={pathNames.settings} element={<ProtectedRoute component={Settings} />} />
            <Route
              path={pathNames.trainingSchedule}
              element={(
                <ProtectedRoute
                  component={TrainingSchedule}
                  roles={[SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER]}
                />
              )}
            />
          </Routes>
        </Sentry.ErrorBoundary>
      </PageLayout>
    </Suspense>
  );
}

export default RoutesWrapper;
