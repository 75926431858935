/* eslint-disable max-len */
import { HStack, Image } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useAppStore } from "../../store/app";
import messages from "./messages";

import britain from "../../assets/britain.png";
import iceland from "../../assets/iceland.png";
import poland from "../../assets/poland.svg";
import danish from "../../assets/denmark.png";
import styles from "./LanguageMenu.module.scss";

import { useUpdatePersonPreferredLanguage } from "./hooks";

function LanguageMenu() {
  const { setLanguage } = useAppStore();
  const { formatMessage } = useIntl();
  const { mutate: updatePersonPreferredLanguage } = useUpdatePersonPreferredLanguage();

  const selectLanguage = (value) => {
    setLanguage(value);
    updatePersonPreferredLanguage({ preferredLanguage: value });
  };

  const translations = {
    "en-GB": {
      value: "en-GB",
      flag: britain,
      translation: {
        id: "LANG_ENGLISH",
        defaultMessage: "English",
      },
    },
    "da-DK": {
      value: "da-DK",
      flag: danish,
      translation: {
        id: "LANG_DANISH",
        defaultMessage: "Danish",
      },
    },
    "is-IS": {
      value: "is-IS",
      flag: iceland,
      translation: {
        id: "LANG_ICELANDIC",
        defaultMessage: "Icelandic",
      },
    },
    "pl-PL": {
      value: "pl-PL",
      flag: poland,
      translation: {
        id: "LANG_POLISH",
        defaultMessage: "Polish",
      },
    },
  };

  return (
    <div className={styles.container}>
      <span className={styles.title} style={{ textTransform: "uppercase" }}>
        {formatMessage(messages.selectLanguage)}
      </span>
      {Object.keys(translations).map((el) => (
        <HStack
          key={translations[el].value}
          className={styles.languageContainer}
          onClick={() => selectLanguage(translations[el].value)}
        >
          <Image width={5} alt="" src={translations[el].flag} />
          <span style={{ paddingLeft: "10px", fontSize: "13px", color: "#1E2022" }}>
            {formatMessage({
              id: translations[el].translation.id,
              defaultMessage: translations[el].translation.defaultMessage,
            })}
          </span>
        </HStack>
      ))}
    </div>
  );
}

export default LanguageMenu;
