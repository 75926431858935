/* eslint-disable import/no-extraneous-dependencies */
import "./instrument";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";

import "@fontsource/roboto";

import {
  BrowserRouter as Router,
  createRoutesFromChildren,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import { theme } from "@chakra-ui/pro-theme";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RoutesWrapper from "./components/RoutesWrapper/RoutesWrapper";
import Oldtheme from "./theme";
import queryClient from "./services/query";
import AuthSyncProvider from "./providers/AuthSyncProvider/AuthSyncProvider";
import AppSettingsProvider from "./providers/AppSettingsProvider/AppSettingsProvider";
import DataSyncProvider from "./providers/DataSyncProvider/DataSyncProvider";
import FormProvider from "./form/providers/FormProvider/FormProvider";
import components from "./form/components";
import DragProvider from "./pages/TrainingSchedule/DragContext";

import useRefreshToken from "./hooks/useRefreshToken";

const proTheme = extendTheme(theme);
const extenstion = {
  colors: { ...proTheme.colors, brand: proTheme.colors.teal },
};
const myTheme = extendTheme(Oldtheme, proTheme);

function App() {
  useRefreshToken();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ChakraProvider theme={myTheme}>
          <AppSettingsProvider>
            <DataSyncProvider>
              <AuthSyncProvider>
                <FormProvider components={components}>
                  <DndProvider backend={HTML5Backend}>
                    <DragProvider>
                      <RoutesWrapper />
                    </DragProvider>
                  </DndProvider>
                </FormProvider>
              </AuthSyncProvider>
            </DataSyncProvider>
          </AppSettingsProvider>
        </ChakraProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
