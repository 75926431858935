import PropTypes from "prop-types";
import { useHideMenu } from "../Menu/hooks";
import Header from "../Header/Header";
import { useAuthStore } from "../../store/auth";
import Footer from "../Footer/Footer";

function PageLayout({ children }) {
  const isMenuHidden = useHideMenu();
  const { isAuthenticated } = useAuthStore();

  if (isMenuHidden) return children;

  return (
    <>
      {isAuthenticated && <Header />}
      {children}
      {isAuthenticated && <Footer />}
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
