import PropTypes from "prop-types";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { FaChevronDown } from "react-icons/fa";

function Dropdown({
  // eslint-disable-next-line no-unused-vars
  name,
  label,
  required,
  items,
  labelProps,
}) {
  const intl = useIntl();
  const requiredErrMessage = intl.formatMessage({
    id: "ERR_FIELD_REQUIRED",
    defaultMessage: "This field is required.",
  });
  const {
    formState: { errors },
  } = useFormContext();

  // const isInputDisabled = getValues('formDisabled') || false;

  // const [isPickerVisible, setIsPickerVisible] = useState(false);

  return (
    <VStack width="100%" marginBottom="8px">
      {/* <Pressable onPress={() => setIsPickerVisible(true)} disabled={isInputDisabled}> */}
      <HStack alignItems="center" justifyContent="space-between" width="100%">
        <Text {...labelProps} color="blue.500">
          {label}
        </Text>
      </HStack>
      {/* </Pressable> */}
      <Controller
        name={name}
        rules={{ required }}
        render={({ field: { value } }) => (
          <HStack
            key={name}
            id={name}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              fontSize="14px"
              fontWeight="bold"
              fontStyle="italic"
              // marginTop="8px"
            >
              {value?.label}
            </Text>
          </HStack>
        )}
      />
      {errors[name] && <Text variant="error">{requiredErrMessage}</Text>}
    </VStack>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
};

Dropdown.defaultProps = {
  label: "Label is required on Dropdown.",
  required: false,
  labelProps: {},
};

export default Dropdown;
