import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuthStore } from "../../store/auth";
import { pathNames } from "../../config/pathNames";

function Redirect({ to }) {
  const location = useLocation();
  const { isAuthenticated } = useAuthStore();

  const isOnboarding = location.pathname.includes(pathNames.companyOnboard);

  if (isAuthenticated && !isOnboarding) {
    return <Navigate to={to ?? pathNames.home} />;
  }

  return <Navigate to={to || pathNames.signIn} replace state={{ from: location }} />;
}

Redirect.propTypes = {
  to: PropTypes.string,
};

Redirect.defaultProps = {
  to: undefined,
};

export default Redirect;
