export default {
  Text: {
    baseStyle: {
      color: "gray.500",
      fontSize: "14px",
    },
    variants: {
      default: {
        // fontFamily: 'Arial',
        fontSize: "16px",
        borderBottomWidth: "1px",
        fontWeight: "400",
        borderBottomColor: "gray.500",
      },
      error: {
        color: "primary.500",
        fontSize: "12px",
        marginTop: "4px",
      },
    },
  },
};
