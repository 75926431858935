import { HStack, VStack } from "@chakra-ui/react";
import ImageUpload from "./ImageUpload/ImageUpload";
import Image from "./Image/Image";
import Input from "./Input/Input";
import Radio from "./Radio/Radio";
import Checkbox from "./Checkbox/Checkbox";
import Text from "./Text/Text";
import Form from "./Form/Form";
import Youtube from "./Youtube/Youtube";
import FormComponentBox from "./FormComponentBox/FormComponentBox";
import DateInput from "./DateInput/DateInput";
import PDFViewer from "./PDFViewer/PDFViewer";
import Dropdown from "./Dropdown/Dropdown";
import DocumentLink from "./DocumentLink/DocumentLink";
import CrewSelect from "./CrewMembers/CrewSelect";

const components = {
  ImageUpload,
  Image,
  Input,
  Radio,
  Checkbox,
  Text,
  Form,
  Box: FormComponentBox,
  HStack,
  Youtube,
  DateInput,
  PDFViewer,
  Dropdown,
  DocumentLink,
  CrewSelect,
  VStack,
};

export default components;
