import { useQuery } from "react-query";
import { getDownloadURL, ref } from "firebase/storage";
import { getStoragePathFromUrl, isFirebaseStorageImage } from "../utils/firebase";
import { storage } from "../config/firebase";

export const FIREBASE_STORAGE_IMAGE_KEY = "storage-image";

export const useStorageImage = (url) => {
  const { isLoading, data } = useQuery(
    [FIREBASE_STORAGE_IMAGE_KEY, url],
    async () => {
      const firebasePath = getStoragePathFromUrl(url);
      const filePath = ref(storage, firebasePath);

      const downloadUrl = await getDownloadURL(filePath);

      return downloadUrl;
    },
    {
      enabled: isFirebaseStorageImage({ url }),
      onSuccess: () => {},
      onError: () => {},
    }
  );

  return {
    isLoading,
    data,
  };
};
