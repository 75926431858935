import { useRef, useEffect } from "react";
import { FormControl, Textarea, Text, Divider } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { isEmpty, isUndefined } from "lodash-es";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";

function Input({ name, required, label, labelProps, isDisabled, ...props }) {
  const {
    formState: { errors },
    getValues,
  } = useFormContext();
  const inputRef = useRef();

  // const [inputDisabled, setInputDisabled] = useState(false);

  const inputDisabled = getValues("formDisabled") || isDisabled;

  const isError = !isUndefined(errors[name]?.message);

  const intl = useIntl();
  const requiredErrMessage = intl.formatMessage({
    id: "ERR_FIELD_REQUIRED",
    defaultMessage: "This field is required.",
  });

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <FormControl isDisabled={inputDisabled}>
      <Controller
        defaultValue=""
        rules={{ required }}
        name={name}
        render={({ field: { onBlur, value, onChange } }) => (
          <>
            {!isEmpty(label) && (
              <Text variant="label" {...labelProps}>
                {label}
              </Text>
            )}
            <div
              key={name}
              id={name}
              style={{
                width: "60%",
                minHeight: "150px",
                border: "1px solid #E2E8F0",
                borderRadius: "5px",
                padding: "10px",
                wordBreak: "break-word",
                resize: "none",
                alignSelf: "center",
              }}
              ref={inputRef}
              {...props}
            >
              {value}
            </div>
            {errors[name] && <Text variant="error">{requiredErrMessage}</Text>}
          </>
        )}
      />
      <Divider sx={{ marginBottom: "20px", marginTop: "20px" }} />
    </FormControl>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
  isDisabled: PropTypes.bool,
};

Input.defaultProps = {
  label: "",
  required: false,
  labelProps: {},
  isDisabled: false,
};

export default Input;
