export default {
  Button: {
    baseStyle: {
      borderRadius: "8px",
      color: "white",
    },
    variants: {
      submit: {
        backgroundColor: "blue.500",
        height: "56px",
        fontSize: "14px",
        width: "75%",
        _hover: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
          _disabled: {
            backgroundColor: "blue.600",
          },
        },
        _active: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
          _hover: {
            boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
            backgroundColor: "blue.600",
          },
        },
        _disabled: {
          backgroundColor: "blue.600",
        },
        textTransform: "uppercase",
      },
      regular: {
        backgroundColor: "blue.500",
        fontSize: "14px",
        minWidth: "250px",
        _hover: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
        },
      },
      important: {
        backgroundColor: "red.500",
        fontSize: "14px",
        minWidth: "250px",
        _hover: {
          boxShadow: " 0 4px 11px rgba(125,55,255,.35)",
          backgroundColor: "red.600",
        },
      },
      scaled: {
        backgroundColor: "blue.500",
        fontSize: "14px",
        _hover: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
          _disabled: {
            backgroundColor: "blue.600",
          },
        },
        _active: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
          _hover: {
            boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
            backgroundColor: "blue.600",
          },
        },
        _disabled: {
          backgroundColor: "blue.600",
        },
      },
      circle: {
        backgroundColor: "blue.500",
        fontSize: "14px",
        borderRadius: "50%",
        _hover: {
          boxShadow: " 0 4px 11px rgba(55,125,255,.35)",
          backgroundColor: "blue.600",
        },
      },
    },
  },
};
