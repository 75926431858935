import { useStore, createStore } from "zustand";
import { persist } from "zustand/middleware";

const initState = {
  email: "",
  uid: null,
  refreshToken: null,
  accessToken: null,
  displayName: "",
  photoUrl: "",
  availableTripsPerAccessCode: {},
  image: "",
  role: null,
  title: null,
  health: null,
  fullName: "",
  fcmTokens: [],
  createdAt: null,
  vesselId: "",
  companyId: "",
  results: [],
  employerRights: null,
  id: "",
  isActive: true,
  isDisabled: false,
  phone: "",
  nameNextOfKin: "",
  phoneNextOfKin: "",
  preferredLanguage: "",
};

export const userStore = createStore(
  persist(
    (set) => ({
      ...initState,
      setUser: (user) => set(() => ({ ...user })),
      resetUser: async () => {
        set(initState);
      },
    }),
    {
      name: "user_store",
    }
  )
);

export const useUserStore = () => useStore(userStore);
