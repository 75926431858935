import { VStack, Text } from "@chakra-ui/react";

function Youtube() {
  return (
    <VStack>
      <Text>Youtube</Text>
    </VStack>
  );
}

export default Youtube;
