import { QueryClient } from "react-query";
import { resourceTimeout } from "./constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: resourceTimeout,
    },
  },
});

export default queryClient;
