import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { dataContainsEmergency } from "../../api/api";
import { fetchDocumentById } from "./documents";

const collectionName = "report";
const reportsCollRef = collection(db, collectionName);
// const companyCollRef = collection(db, 'company');

// function that gets all reports
export const fetchAllReports = async () => {
  const snap = await getDocs(reportsCollRef);

  const reports = [];

  snap.forEach((v) => {
    const data = v.data();
    const { id } = v;

    const report = { id, ...data };

    reports.push(report);
  });

  return reports;
};

// function to fetch reports by category for home-page
export const fetchFeportsByCategory = async ({
  companyId,
  vesselId,
  masterCategoryId,
  isSuperAdmin,
}) => {
  if (vesselId) {
    const q = query(
      reportsCollRef,
      where("vesselId", "==", vesselId),
      where("masterCategoryId", "==", masterCategoryId)
    );
    const querySnapshot = await getDocs(q);

    const reports = [];

    querySnapshot.forEach((d) => {
      const data = d.data();
      const { id: documentId } = d; // get the document id
      reports.push({ documentId, ...data });
    });

    const addedChecks = dataContainsEmergency(reports);

    return addedChecks || reports; // this will return an array of reports
  }

  if (companyId) {
    const companyRef = doc(db, "company", companyId);
    const companySnapshot = await getDoc(companyRef);

    const vesselIds = companySnapshot.data().vessels ?? [];

    const newQuery = query(
      reportsCollRef,
      where("vesselId", "in", vesselIds),
      where("masterCategoryId", "==", masterCategoryId)
    );
    const querySnapshot = await getDocs(newQuery);

    const reports = [];

    querySnapshot.forEach((d) => {
      const data = d.data();
      const { id: documentId } = d;
      reports.push({ documentId, ...data });
    });

    const addedChecks = dataContainsEmergency(reports);

    return addedChecks || reports;
  }

  if (!isSuperAdmin) return [];

  const q = query(reportsCollRef, where("masterCategoryId", "==", masterCategoryId));
  const querySnapshot = await getDocs(q);

  const reports = [];

  querySnapshot.forEach((d) => {
    const data = d.data();
    const { id: documentId } = d; // get the document id
    reports.push({ documentId, ...data });
  });

  const addedChecks = dataContainsEmergency(reports);

  return addedChecks || reports;
};

// function that gets all reports that match id from the parameter
export const fetchReportByUserId = async ({ uid }) => {
  // Create a query against the collection
  const q = query(reportsCollRef, where("uid", "==", uid.uid));
  const querySnapshot = await getDocs(q);

  const reports = [];

  querySnapshot.forEach((d) => {
    const data = d.data();
    const { id: documentId } = d; // get the document id
    reports.push({ documentId, ...data });
  });

  return reports; // this will return an array of reports
};

export const fetchReportAndDocumentById = async ({ reportId }) => {
  const docRef = doc(db, collectionName, reportId);
  const snap = await getDoc(docRef);

  const report = snap.data();

  const document = await fetchDocumentById({ documentId: report?.id });

  return { report, document };
};

// function that gets all reports that match id from the parameter
export const fetchReportByVessel = async ({ vesselId }) => {
  // Create a query against the collection
  const q = query(reportsCollRef, where("vesselId", "==", vesselId.vesselId));
  const querySnapshot = await getDocs(q);

  const reports = [];

  querySnapshot.forEach((d) => {
    const data = d.data();
    const { id } = d; // get the document id
    reports.push({ id, ...data });
  });

  return reports; // this will return an array of reports
};

// function that gets all reports that match vesselID from the passed array
export const fetchReportsByVessel = async ({ vesselIds }) => {
  const reports = [];

  vesselIds.map(async (vesselId) => {
    const q = query(reportsCollRef, where("vesselId", "==", vesselId));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((d) => {
      const data = d.data();
      const { id } = d; // get the document id
      reports.push({ id, ...data });
    });
  });

  return reports;
};
