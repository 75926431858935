import { useStore, createStore } from "zustand";
import { persist } from "zustand/middleware";
import { normalizeLocale } from "../constants";
import { getBrowserLanguage } from "../utils/utils";
import { DEFAULT_LANGUAGE } from "../locale/translations";

const initState = {
  language: normalizeLocale(getBrowserLanguage()) || DEFAULT_LANGUAGE,
  itemsPerPage: 100,
};

export const appStore = createStore(
  persist(
    (set) => ({
      ...initState,
      setLanguage: (language) => set(() => ({ language })),
      setItemsPerPage: (itemsPerPage) => set(() => ({ itemsPerPage })),
    }),
    {
      name: "app_store",
    }
  )
);

export const useAppStore = () => useStore(appStore);
