/* eslint-disable import/prefer-default-export */
import { useMutation } from "react-query";
import { useUserStore } from "../../store/user";
import { updateUserPreferredLanguage } from "../../services/firebase/firestore/user";

export const useUpdatePersonPreferredLanguage = () => {
  const { id } = useUserStore();
  const { isLoading, mutate } = useMutation(
    ({ preferredLanguage }) => updateUserPreferredLanguage({
      userId: id,
      preferredLanguage,
    }),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return {
    isLoading,
    mutate,
  };
};
