/* eslint-disable react/jsx-one-expression-per-line */
import { VStack, Text, HStack, Divider, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
// import { Divider } from 'rsuite';
import { fetchReportAndDocumentById } from "../../../services/firebase/firestore/reports";
import { formatDateOnly, formatTimeOnly } from "../../../utils/utils";

function FormHeader({ title }) {
  const { reportId } = useParams();

  const { data } = useQuery(
    ["report", reportId],
    () => fetchReportAndDocumentById({
      reportId,
    }),
    {
      staleTime: Infinity,
    }
  );

  const intl = useIntl();
  const headerHeight = "116px";

  const labelColor = "gray.900";
  const textColor = "black";
  const ssn = data?.report?.input_user_kt || "N/a";

  return (
    <VStack
      space="12px"
      minHeight={headerHeight}
      paddingBottom="20px"
      justifyContent="space-between"
      width="100%"
      alignItems="flex-start"
    >
      <HStack space="16px" alignItems="center" width="100%" mb="10px">
        <Image src="/img/logo250x77.png" maxHeight="50px" mr="40px" />
        <Text flexGrow={1} fontSize="24px" fontWeight="bold" color="primary.500" lineHeight="28px">
          {intl.formatMessage({ id: "REGISTRATION_REPORT", defaultMessage: "Registration report" })}
        </Text>
      </HStack>
      <VStack space="4px" alignItems="flex-start">
        <HStack space="16px" alignItems="center">
          <Text color={labelColor} opacity={0.6} lineHeight="20px">
            {intl.formatMessage({ id: "DATE2", defaultMessage: "Date: " })}
          </Text>
          <Text color={textColor} fontWeight="700" lineHeight="20px">
            {formatDateOnly(data?.report?.submittedAt)}
          </Text>
        </HStack>
        <HStack space="16px" alignItems="center">
          <Text color={labelColor} opacity={0.6} lineHeight="20px">
            {intl.formatMessage({ id: "TIME", defaultMessage: "Time: " })}
          </Text>
          <Text color={textColor} fontWeight="700" lineHeight="20px">
            {formatTimeOnly(data?.report?.submittedAt)}
          </Text>
        </HStack>
      </VStack>
      <HStack space="16px" alignItems="center">
        <Text color={labelColor} opacity={0.6} lineHeight="20px">
          {intl.formatMessage({ id: "REGISTER_NAME", defaultMessage: "Name" })}
        </Text>
        <Text color={textColor} fontWeight="700" lineHeight="20px">
          {data?.report?.fullName}
        </Text>
      </HStack>
      <HStack space="16px" alignItems="center">
        <Text color={labelColor} opacity={0.6} lineHeight="20px">
          {intl.formatMessage({ id: "SHIP", defaultMessage: "Ship" })}:{" "}
        </Text>
        <Text color={textColor} fontWeight="700" lineHeight="20px">
          {data?.report?.vesselName}
        </Text>
      </HStack>
      <HStack space="16px" alignItems="center">
        <Text color={labelColor} opacity={0.6} lineHeight="20px">
          {intl.formatMessage({ id: "SSN", defaultMessage: "SSN" })}:{" "}
        </Text>
        <Text color={textColor} fontWeight="700" lineHeight="20px">
          {ssn}
        </Text>
      </HStack>
      <HStack space="16px" alignItems="center">
        <Text color={labelColor} opacity={0.6} lineHeight="20px">
          {intl.formatMessage({ id: "REG_TYPE", defaultMessage: "Registration type" })}:{" "}
        </Text>
        <Text color={textColor} fontWeight="700" lineHeight="20px">
          {title}
        </Text>
      </HStack>
      <Divider orientation="horizontal" borderWidth="2px" color="primary.500" />
    </VStack>
  );
}

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormHeader;
