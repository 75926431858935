// eslint-disable-next-line import/prefer-default-export
export const endpoints = {
  assignments: "/assignments",
  assignment: "/assignments/details",
  assignmentComments: "/assignments/comments",
  assignmentSendEmail: "/assignments/send-email",
  assignmentSendEmailStatusChange: "/assignments/send-email-status-change",
  assignmentSendEmailToResponsibleOnComment: "/assignments/send-email-on-comment",
  hiddenAssignments: "/assignments/hidden",
  completedAssignments: "/assignments/completed",
  assignmentImages: "/assignments/images",
  logsAdd: "/logs/add",
  logsGet: "/logs",
  users: "/users",
  vessels: "/vessels",
  settings: "/settings",
  allUsers: "/users/all",
  userFcmToken: "/users/fcm-token",
  drills: "/drills",
  trainingSchedules: "/training-schedules",
  shiftPlan: "/shiftplan",
  cms: "/cms",
};
