import PropTypes from "prop-types";
import { Center, Spinner } from "@chakra-ui/react";
import { useDataSync } from "./hooks";

function DataSyncProvider({ children }) {
  const { isLoading } = useDataSync();

  return (
    <>
      {isLoading && (
        <Center width="100vw" height="100vh">
          <Spinner />
        </Center>
      )}
      {!isLoading && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </>
  );
}

DataSyncProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataSyncProvider;
