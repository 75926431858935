import { collection, getDocs, doc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { userStore } from "../../../store/user";

const collectionName = "notification";
const notificationsCollRef = collection(db, collectionName);

export const fetchAllNotifications = async () => {
  const user = userStore.getState();
  const snap = await getDocs(notificationsCollRef);

  const notifications = [];

  snap.forEach((notificationDoc) => {
    const data = notificationDoc.data();
    const { id } = notificationDoc;
    const notificationId = notificationDoc.id;

    const notification = { id, notificationId, ...data };

    notifications.push(notification);
  });

  const finalNotifications = notifications.filter((notification) => {
    if (user.id === notification.entityId) {
      return notification;
    }
    if (user.vesselId === notification.entityId) {
      return notification;
    }
    return null;
  });

  return finalNotifications;
};

async function updateUserImage(user) {
  if (user.image === null || user.image === undefined) {
    const userRef = doc(db, "users", user.id);
    await setDoc(userRef, { image: "1" }, { merge: true });
  }
}

export const markNotificationsAsRead = async (notifications) => {
  const user = userStore.getState();

  // Filter notifications based on entityId
  // eslint-disable-next-line max-len
  const relevantNotifications = notifications.filter(
    (notification) => user.id === notification.entityId || user.vesselId === notification.entityId
  );

  // Filter out already-read notifications
  // eslint-disable-next-line max-len
  const unreadNotifications = relevantNotifications.filter(
    (notification) => !Array.isArray(notification.readBy) || !notification.readBy.includes(user.id)
  );

  if (unreadNotifications.length === 0) {
    return { updatedCount: 0 };
  }

  const updateResults = {
    successes: [],
    failures: [],
  };

  const updatePromises = unreadNotifications.map(async (notification) => {
    try {
      const notificationRef = doc(notificationsCollRef, notification.notificationId);
      const newReadByArray = [...(notification.readBy || []), user.id];
      await updateDoc(notificationRef, { readBy: newReadByArray });

      updateResults.successes.push(notification.notificationId);
    } catch (error) {
      updateResults.failures.push({ id: notification.notificationId, error });
    }
  });
  updateUserImage(user);
  await Promise.all(updatePromises);

  return {
    message: `Updated notifications. Successes: ${updateResults.successes.length}. Failures: ${updateResults.failures.length}.`,
    updatedCount: updateResults.successes.length,
    failedCount: updateResults.failures.length,
    failedDetails: updateResults.failures,
  };
};

// export const markNotificationsAsRead = async (notifications) => {
//   const updatePromises = notifications
//     .filter((notification) => !notification.isRead)
//     .map((notification) => {
//       console.log('test2');
//       const notificationRef = doc(db, collectionName, notification.id);
//       return updateDoc(notificationRef, { isRead: true });
//     });
//   console.log('test');

//   await Promise.all(updatePromises);
// };

// export const fetchNotificationsByEntityID = async ({ entityID, user }) => {
//   if (!entityID) {
//     throw new Error('Entity ID is required.');
//   }

//   if (!user) {
//     throw new Error('User is required.');
//   }

//   const notifications = [];

//   // eslint-disable-next-line no-shadow
//   const pushToNotifications = (doc) => {
//     const data = doc.data();
//     const { id } = doc;
//     notifications.push({ id, ...data });
//   };

//   if (user.id === entityID) {
//     const idQuery = query(notificationsCollRef, where('entityID', '==', user.id));
//     const idQuerySnapshot = await getDocs(idQuery);
//     idQuerySnapshot.forEach(pushToNotifications);
//   }

//   if (user.vesselID === entityID) {
//     const vesselIdQuery = query(notificationsCollRef, where('entityID', '==', user.vesselID));
//     const vesselIdQuerySnapshot = await getDocs(vesselIdQuery);
//     vesselIdQuerySnapshot.forEach(pushToNotifications);
//   }

//   return notifications;
// };
