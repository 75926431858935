import { useEffect, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useIntl } from "react-intl";
import { useAuthStore } from "../../store/auth";
import { useUserStore } from "../../store/user";
import { auth, onAuthStateChanged } from "../../config/firebase";
import { useIsTabActive } from "./hooks";
import useToast from "../../hooks/useToast";

function AuthSyncProvider({ children }) {
  const { updateAccessToken, handleLogout, updateTokenExpiration } = useAuthStore();
  const { resetUser, isDisabled } = useUserStore();

  const isTabActive = useIsTabActive();
  const toast = useToast();
  const { formatMessage } = useIntl();

  const logoutDisabledUser = useCallback(() => {
    toast({
      title: formatMessage({ id: "LOGIN", defaultMessage: "Authentication" }),
      description: formatMessage({
        id: "LOGIN_USER_DISABLED",
        defaultMessage: "This account is disabled. Please contact an administrator.",
      }),
    });
    handleLogout();
    resetUser();
  }, [handleLogout, resetUser, toast, formatMessage]);

  useEffect(() => {
    if (isDisabled) {
      logoutDisabledUser();
    }

    /* Listens to the authentication state of the user. */
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult(true);
          const { expirationTime } = idTokenResult;
          const expirationTimeInSeconds = new Date(expirationTime).getTime() / 1000;

          updateTokenExpiration(expirationTimeInSeconds);

          const now = Date.now() / 1000;
          const timeUntilExpiration = expirationTimeInSeconds - now;

          if (timeUntilExpiration < 600) {
            const newIdToken = await user.getIdToken(true);
            updateAccessToken(newIdToken);
            // console.log({ title: 'Token Refreshed (Near Expiration)', description: 'New token generated' });
          }
        } catch (error) {
          if (error.code === "auth/user-disabled") {
            logoutDisabledUser();
          } else {
            console.error({ title: "Error", description: error.toString() });
            Sentry.withScope((scope) => {
              scope.setLevel("error");
              scope.setTag("app-location", "AuthSyncProvider");
              Sentry.captureException(new Error(error));
            });
          }
        }
      } else {
        handleLogout();
        resetUser();
      }
    });

    return () => unsubscribe();
  }, [handleLogout, resetUser, updateAccessToken, updateTokenExpiration, isDisabled, isTabActive]);

  // useEffect(() => {
  //   console.log('Is Tab Active:', isTabActive);
  // }, [isTabActive]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
}

export default AuthSyncProvider;
