import { defineMessages } from "react-intl";

const messages = defineMessages({
  selectLanguage: {
    id: "SELECT_LANGUAGE",
    defaultMessage: "Select Language",
  },
});

export default messages;
