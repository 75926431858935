import { defineMessages } from "react-intl";

const messages = defineMessages({
  home: {
    id: "HOME_PAGE",
    defaultMessage: "Home",
  },
  vessels: {
    id: "VESSELS",
    defaultMessage: "Vessels",
  },
  companies: {
    id: "COMPANIES",
    defaultMessage: "Companies",
  },
  users: {
    id: "USERS",
    defaultMessage: "Users",
  },
  errorOnFetchingUser: {
    id: "ERROR_FETCHING_USER",
    defaultMessage: "Error on fetching user",
  },
  docBuilder: {
    id: "DOCUMENT_BUILDER",
    defaultMessage: "Document Builder",
  },
  documents: {
    id: "DOCUMENTS",
    defaultMessage: "Documents",
  },
  data: {
    id: "DATA",
    defaultMessage: "Data",
  },
  trainingDrills: {
    id: "TRAINING DRILLS",
    defaultMessage: "Training drills",
  },
  inspections: {
    id: "INSPECTIONS",
    defaultMessage: "Inspections",
  },
  safetyInductions: {
    id: "SAFETY INDUCTIONS",
    defaultMessage: "Safety inductions",
  },
  notifications: {
    id: "NOTIFICATIONS",
    defaultMessage: "Notifications",
  },
  sendNotification: {
    id: "SEND_NOTIFICATION",
    defaultMessage: "Send Notification",
  },
  profile: {
    id: "PROFILE",
    defaultMessage: "Profile",
  },
  healthCertificateAndEducation: {
    id: "HEALTH_CERT_AND_EDU",
    defaultMessage: "Health Certificate and Education",
  },
  reports: {
    id: "REPORTS",
    defaultMessage: "Reports",
  },
  PowerBI: {
    id: "POWERBI",
    defaultMessage: "Dashboard",
  },
  CrewCall: {
    id: "CREW_CALL",
    defaultMessage: "Crew call",
  },
  feedback: {
    id: "FEEDBACK",
    defaultMessage: "Feedback",
  },
  assignments: {
    id: "ASSIGNMENTS",
    defaultMessage: "Assignments",
  },
  comments: {
    id: "COMMENTS",
    defaultMessage: "Comments",
  },
  auditlogs: {
    id: "AUDITLOGS",
    defaultMessage: "Audit logs",
  },
  userSettings: {
    id: "USER_SETTINGS",
    defaultMessage: "Settings",
  },
  trainingSchedule: {
    id: "TRAINING_SCHEDULE",
    defaultMessage: "Training schedule",
  },
  cms: {
    id: "CMS",
    defaultMessage: "Content Manager",
  },
});

export default messages;
