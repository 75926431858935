import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { isEmpty, isUndefined } from "lodash-es";
import { useAuthStore } from "../../store/auth";
import Redirect from "../Redirect/Redirect";
import { useUserStore } from "../../store/user";

function ProtectedRoute({ isProtected, component: RouteComponent, roles, ...props }) {
  const location = useLocation();
  const isOnboarding = location.pathname.includes("company-onboard");

  const { isAuthenticated } = useAuthStore();
  const user = useUserStore();

  if (isProtected && isAuthenticated) {
    const roleIsRequired = !isEmpty(roles);

    if (roleIsRequired) {
      const userRole = user?.role;
      const role = roles.find((r) => r?.key === userRole);

      const userHaveAccess = !isUndefined(role);

      if (userHaveAccess) {
        return <RouteComponent {...props} />;
      }
    }

    if (!roleIsRequired) {
      return <RouteComponent {...props} />;
    }
  }

  if (!isProtected && isAuthenticated && isOnboarding) {
    return <RouteComponent {...props} />;
  }

  if (!isProtected && !isAuthenticated) {
    return <RouteComponent {...props} />;
  }

  return <Redirect />;
}

ProtectedRoute.propTypes = {
  isProtected: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
};

ProtectedRoute.defaultProps = {
  isProtected: true,
  roles: [],
};

export default ProtectedRoute;
