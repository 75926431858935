import { defineMessages } from "react-intl";

const toast = defineMessages({
  genericErrorHeading: {
    id: "TOAST.GENERIC_ERROR.HEADING",
    defaultMessage: "Something went wrong",
  },
  genericErrorDescription: {
    id: "TOAST.GENERIC_ERROR.DESCRIPTION",
    defaultMessage: "Please try again later",
  },
  requestTimeoutHeading: {
    id: "TOAST.REQUEST_TIMEOUT.HEADING",
    defaultMessage: "Slow network is detected",
  },
  requestTimeoutDescription: {
    id: "TOAST.REQUEST_TIMEOUT.DESCRIPTION",
    defaultMessage: "Please try again later",
  },
  genericSuccessMessage: {
    id: "TOAST.GENERIC_SUCCESS.ACTION_SUCCESSFUL",
    defaultMessage: "Action successful",
  },
  useractivationSuccessMessage: {
    id: "TOAST.USER_ACTIVATION.ACTION_SUCCESSFUL",
    defaultMessage: "User activation successful",
  },
  genericAction: {
    id: "TOAST.GENERIC_ACTION.ACTION",
    defaultMessage: "Action in progress..",
  },
});

export default toast;
