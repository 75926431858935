import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

function getEnvironment() {
  const apiUrl = process.env.REACT_APP_ENVIRONMENT || "development";

  return apiUrl;
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: getEnvironment(),
  enabled: getEnvironment() !== "development",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: [
    /^\//,
    /^https:\/\/api-service\.stigaolduna\.is\/alda/,
    /^https:\/\/api-service\.stigaolduna\.is\/alda-staging/,
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
