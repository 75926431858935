import PropTypes from "prop-types";
import { Link, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useActiveMenuProperties } from "../hooks";

function SubMenuItem({ path, label, onToggle }) {
  const { isItemActive } = useActiveMenuProperties();

  return (
    <Link
      key={path}
      as={NavLink}
      onClick={onToggle}
      to={path}
      paddingRight={6}
      paddingLeft={14}
      paddingY={3}
      backgroundColor={isItemActive && "gray.200"}
      _hover={{
        backgroundColor: isItemActive ? "gray.200" : "gray.100",
      }}
    >
      <Text>{label}</Text>
    </Link>
  );
}

SubMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SubMenuItem;
