/* eslint-disable quotes */
import { extendTheme } from "@chakra-ui/react";
import inputTheme from "./components/input";
import buttonTheme from "./components/button";
import textTheme from "./components/text";
import formTheme from "./components/form";

const theme = extendTheme({
  components: {
    ...inputTheme,
    ...buttonTheme,
    ...textTheme,
    ...formTheme,
  },
  styles: {
    global: {
      html: {
        height: "100%",
        overflowY: "scroll",
        whiteSpace: "inherit",
        wordBreak: "break-word",
        wordWrap: "break-word",
        textSizeAdjust: "100%",
        mozOsxFontSmoothing: "grayscale",
        webkitFontSmoothing: "antialiased",
        backgroundColor: "#F7FAFC",
      },
      body: {
        display: "flex",
        flexFlow: "column nowrap",
        height: "auto",
        margin: 0,
        minHeight: "100%",
        font: "14px 'Inter', sans-serif",
        backgroundColor: "#F7FAFC",
        bgColor: "#F7FAFC",
        bg: "#F7FAFC",
      },
      heading: {
        display: "flex",
        flexFlow: "column nowrap",
        height: "auto",
        margin: 0,
        minHeight: "100%",
        font: "14px 'Inter', sans-serif",
        backgroundColor: "#F7FAFC",
        bgColor: "#F7FAFC",
      },
      "#root": {
        flex: 1,
        display: "flex",
        flexFlow: "column nowrap",
      },
    },
  },
  colors: {
    brand: {
      100: "#F7FAFC88",
    },
    primary: {
      500: "#4A5568",
    },
    gray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      500: "#718096",
      600: "#4A5568",
      900: "#171923",
    },
    error: {
      500: "#F9342C",
    },
    blue: {
      400: "#2BBBD9",
      500: "#227FAF",
    },
    green: {
      500: "#499443",
    },
    orange: {
      100: "#e56146",
      200: "#c26d5a",
    },
    // gray: {
    //   50: '#f5f5f5',
    //   100: '#ebebeb',
    //   200: '#d6d6d6',
    //   300: '#c2c2c2',
    //   400: '#adadad',
    //   500: '#999999',
    //   600: '#7a7a7a',
    //   700: '#5c5c5c',
    //   800: '#3d3d3d',
    //   900: '#1f1f1f',
    // },
  },
  // fonts: {
  //   heading: '\'Inter\', sans-serif',
  //   body: '\'Inter\', sans-serif',
  //   customFont: '\'Inter\', sans-serif',
  // },
  fonts: {
    heading: `'Roboto', sans-serif`,
    body: `'Roboto', sans-serif`,
    customFont: `'Roboto', sans-serif`,
  },
});

export default theme;
