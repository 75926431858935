// eslint-disable-next-line import/prefer-default-export
export const endpoints = {
  users: "/users",
  companies: "/companies",
  vessels: "/vessels",
  documents: "/documents",
  sendNotification: "/notifications/send",
  reports: "/reports",
  reportsTen: "/reports/topten",
  reportsCat: "/reports/category",
  reportsDetails: "/reports/details",
  feedback: "/users/email",
  emailReport: "/users/email-report",
  emailAssignment: "/users/email-assignment",
  assignments: "/assignments",
  assignment: "/assignments/details",
  fcmSendUser: "/fcm/send/user",
  fcmSendUsers: "/fcm/send/users",
  fcmSendTopic: "/fcm/send/topic",
};
