import PropTypes from "prop-types";
import { Image, Spinner, VStack } from "@chakra-ui/react";
import { useStorageImage } from "../../../../hooks/useStorageImage";
import { isFirebaseStorageImage } from "../../../../utils/firebase";

function ImagePreview({
  // eslint-disable-next-line no-unused-vars
  photo,
  index,
  url,
}) {
  const isFirebaseLink = isFirebaseStorageImage({ url });
  const { filename, path } = photo;
  const { isLoading, data: firebaseStorageUrl } = useStorageImage(url);

  return (
    <VStack marginRight="8px">
      {isLoading && <Spinner />}
      {!isLoading && !isFirebaseLink && (
        <Image
          src={path || url}
          alt={filename || `image-${index}`}
          width="100%"
          // height="310px"
          backgroundSize="cover"
          borderRadius="4px"
        />
      )}
      {!isLoading && isFirebaseLink && (
        <Image
          src={firebaseStorageUrl || path || url}
          alt={filename || `image-${index}`}
          width="100%"
          // height="310px"
          backgroundSize="cover"
          borderRadius="4px"
          maxW="500px"
        />
      )}
    </VStack>
  );
}

ImagePreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  photo: PropTypes.object,
  index: PropTypes.number.isRequired,
  url: PropTypes.string,
};

ImagePreview.defaultProps = {
  photo: {},
  url: null,
};

export default ImagePreview;
